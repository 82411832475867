* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  color: black;
  padding: 10px 20px;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar .navdiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.navbar .logo a {
  color: rgb(14, 13, 13);
  text-decoration: none;
  font-size: 1.5em;
  font-weight: bold;
}

.navbar ul {
  list-style: none;
  display: flex;
}

.navbar ul li {
  margin: 0 10px;
}

.navbar ul li input,
.navbar ul li select,
.navbar ul li button {
  padding: 8px;
  margin: 5px;
  border-radius: 8px;
  border: 2px solid black;
  font-size: 0.9em;
  width: 210px;
  height: 50px;
  background-color: white;
  color: black;
  transition: all 0.3s ease;
}

#status {
  font-weight: bold;
  font-family: 'Arial', sans-serif;
  font-size: 18px;
  color: #333333;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  border-radius: 3px;
  text-align: center;
  margin-top: 3px;
}

#startButton,
#stopButton,
#signoutButton {
  background-color: white;
  border: 2px solid black;
  border-radius: 8px;
  padding: 8px;
  font-size: 0.7em;
  width: 180px;
  height: 50px;
}

.navbar ul li button:hover {
  background-color: #f0f0f0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.navbar ul li button:active {
  background-color: #e0e0e0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transform: translateY(2px);
}

#domain:focus,
#jd:focus,
#startButton:focus,
#stopButton:focus,
#signoutButton:focus {
  border-color: black;
  outline: none;
}

#domain,
#jd {
  font-size: 14px;
  border: 2px solid black;
  border-radius: 8px;
  padding: 8px;
  width: 180px;
}

#domain:focus,
#jd:focus {
  border-color: black;
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
}

.qa-box {
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #ffffff;
  border-radius: 5px;
  width: 90%;
  max-height: 90%;
  overflow-y: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  text-align: left;
  color: #333333;
  box-sizing: border-box;
  margin-top: 15vh;
}

.qa-box ul {
  margin-bottom: 1px;
  display: block;
}

.qa-box li {
  margin-bottom: 1px;
  display: block;
}

.qa-box pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 18px;
  margin: 0;
  line-height: 1.2;
}

.qa-box strong {
  color: #000000;
}

@media (max-width: 768px) {
  .navbar .navdiv {
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar ul {
    flex-direction: column;
    width: 100%;
  }

  .navbar ul li {
    margin: 5px 0;
  }

  .navbar ul li input,
  .navbar ul li select,
  .navbar ul li button {
    width: 100%;
    padding: 10px;
    font-size: 1em;
  }

  #status {
    margin-top: 5px;
    padding: 8px;
    border: 1px solid #ccc;
    background-color: #ffffff;
    border-radius: 5px;
    text-align: center;
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .navbar {
    padding: 10px;
  }

  .navbar .logo a {
    color: rgb(14, 13, 13);
    text-decoration: none;
    font-size: 1.5em;
    font-weight: bold;
  }

  .navbar ul li input,
  .navbar ul li select,
  .navbar ul li button {
    padding: 8px;
    font-size: 0.9em;
  }

  #startButton,
  #stopButton,
  #signoutButton {
    padding: 8px;
    font-size: 14px;
    transition: background-color 0.3s, box-shadow 0.3s;
  }

  #status {
    font-size: 14px;
    padding: 6px;
    margin-top: 5px;
  }
}